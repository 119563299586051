import PropType from "prop-types";
import { generatePath, Link } from "react-router-dom";
import { Car, Clock, Day, Place, Room } from "../../assets/icons";
import { useTranslate } from "../../language/i18n";
import getLatestInternalComment from "../../utils/getLatestInternalComment";
import { getPlace } from "../../utils/getPlace";
import pages from "../../utils/pages";
import Checkbox from "../shared/Checkbox/Checkbox";
import styles from "./FittingsPage.module.scss";
import Label from "./Label";

const AdminTableRow = ({ fitting, showColumn, selected, setSelected, status }) => {
  const translate = useTranslate();
  const isSelected = selected.some(id => id === fitting.nextMeeting?.id);

  const toggleSelected = nextMeeting => {
    const exists = selected.some(id => id === nextMeeting.id);
    const selection = exists ? selected.filter(id => id !== nextMeeting.id) : [...selected, nextMeeting.id];
    setSelected(selection);
  };

  const showParticipantInfo = status?.id === "WAITING" || status?.id === "CLOSED";

  return (
    <tr key={fitting.id}>
      {showColumn("CHECKBOX") && (
        <td>
          <Checkbox onChange={() => toggleSelected(fitting.nextMeeting)} checked={isSelected} />
        </td>
      )}
      <td>
        <div className={styles.singleRow}>
          <Link
            to={{
              pathname: generatePath(pages.EMBEDDED_MY_FITTING.PATH, { fitting: fitting.id, type: "info" }),
              state: {
                path: `${window.location.pathname}${window.location.search}`,
                text: "FITTING_ADMIN_PLANNING",
                fromComponent: `${window.location.pathname}${window.location.search}`,
              },
            }}
          >
            {`${translate("FITTING")} ${fitting.contentNumber} / ${fitting.fittingType?.name}`}
          </Link>
        </div>
        <div className={showParticipantInfo ? styles.row : styles.singleRow}>
          <Label label={translate("ORDERED")} value={fitting.orderDate} customCssClass={styles.cleanSpacing} />
          {showParticipantInfo && (
            <Label
              label={translate("CONSULTANT")}
              value={fitting?.consultant ? `${fitting?.consultant?.firstName} ${fitting?.consultant?.surname}` : ""}
              customCssClass={styles.cleanSpacing}
            />
          )}
        </div>
        <div className={showParticipantInfo ? styles.row : styles.singleRow}>
          <Label
            label={translate("PRESCRIBER")}
            value={`${fitting.prescriber?.firstName} ${fitting.prescriber?.surname}`}
            customCssClass={styles.cleanSpacing}
          />
          {showParticipantInfo && (
            <Label
              label={translate("TECHNICIAN")}
              value={fitting?.technician ? `${fitting.technician?.firstName} ${fitting.technician?.surname}` : ""}
              customCssClass={styles.cleanSpacing}
            />
          )}
        </div>
      </td>
      {showColumn("INTERNAL_COMMENT") && (
        <td>
          <div className={styles.singleRow}>{getLatestInternalComment(fitting.fittingComments?.internalComments)}</div>
        </td>
      )}
      {showColumn("BOOKING") && (
        <td>
          <div className={styles.singleRow}>{fitting.nextMeeting?.comment}</div>
        </td>
      )}
      {showColumn("MEETING") && (
        <td>
          <div className={styles.row}>
            <div>
              <Day customCssClass={styles.icon} />
              {fitting.nextMeeting?.date}
            </div>
            <div>
              <Clock customCssClass={styles.icon} />
              {fitting.nextMeeting?.fromTime && fitting.nextMeeting?.toTime
                ? `${fitting.nextMeeting?.fromTime} - ${fitting.nextMeeting?.toTime}`
                : ""}
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Place customCssClass={styles.icon} />
              {getPlace(fitting?.nextMeeting, translate("CLIENT"))}
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Room customCssClass={styles.icon} />
              {fitting.nextMeeting?.room?.label || ""}
            </div>
            <div>
              <Label
                label={translate("CONSULTANT")}
                value={`${fitting.nextMeeting?.consultant?.firstName || ""} ${
                  fitting.nextMeeting?.consultant?.surname || ""
                }`}
                customCssClass={styles.cleanSpacing}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Car customCssClass={styles.icon} />
              {fitting.nextMeeting?.car?.label || ""}
            </div>
            <div>
              <Label
                label={translate("TECHNICIAN")}
                value={`${fitting.nextMeeting?.technician?.firstName || ""} ${
                  fitting.nextMeeting?.technician?.surname || ""
                }`}
                customCssClass={styles.cleanSpacing}
              />
            </div>
          </div>
        </td>
      )}
      {showColumn("CLOSED") && (
        <td className={styles.completedStatus}>
          <div>
            {`${fitting?.status?.value === 90 ? translate("COMPLETED") : translate("ANNULLED")}: ${
              fitting.closedDateTime
            }`}
          </div>
        </td>
      )}
    </tr>
  );
};

AdminTableRow.propTypes = {
  fitting: PropType.object,
  selected: PropType.array,
  setSelected: PropType.func,
  edit: PropType.bool,
  showColumn: PropType.func,
  status: PropType.object,
};

export default AdminTableRow;
