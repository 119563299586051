import { Button, InputField, THEME } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useTranslate } from "../../../language/i18n";
import { DATE, getFullDate } from "../../../utils/DateUtil";
import Modal from "../../shared/Modals/Modal";

const EditRequestedDeliveryDateModal = ({ visible, onClose, onSave, deliveryDate, requestedDeliveryDate }) => {
  const translate = useTranslate();
  const [date, setDate] = useState(
    requestedDeliveryDate ? getFullDate(requestedDeliveryDate) : getFullDate(deliveryDate),
  );

  const getMinDate = () => {
    const current = new Date();
    const delivery = new Date(deliveryDate);
    if (delivery < current) return getFullDate(current);
    return getFullDate(delivery);
  };

  return (
    <Modal
      title={translate("CHANGE_ETA")}
      visible={visible}
      onClose={onClose}
      footerContent={
        <>
          <Button theme={THEME.SECONDARY} onClick={onClose}>
            {translate("CANCEL")}
          </Button>
          <Button theme={THEME.PRIMARY} onClick={() => onSave(date)}>
            {translate("SAVE")}
          </Button>
        </>
      }
    >
      <InputField
        type={DATE}
        max={"9999-12-31"}
        value={date}
        onChange={event => setDate(event.target.value)}
        onClear={() => setDate(deliveryDate)}
        min={getMinDate()}
      />
    </Modal>
  );
};

EditRequestedDeliveryDateModal.propTypes = {
  onClose: PropType.func,
  onSave: PropType.func,
  visible: PropType.bool,
  deliveryDate: PropType.number,
  requestedDeliveryDate: PropType.number,
};

export default EditRequestedDeliveryDateModal;
